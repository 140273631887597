<template>
  <div>
    <div class="page-header">后台批量注册企业</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="formCheck"
      class="login-form"
    >
      <div class="block">
        <div class="tips">
          <h3>添加规则</h3>
          <div>1、选择需要批量添加的企业类型</div>
          <div>2、在多文本框内输入需要注册的企业信息</div>
          <div>格式为：</div>
          <div>
            &lt;企业名称&gt;,&lt;手机号&gt;,&lt;管理员用户名&gt;,&lt;密码&gt;
          </div>
          <div>注,每个字段间用","隔开,每一行代表一个企业,例如</div>
          <div>小芳家具有限公司,14777777777,钱周能,999999</div>
          <div>亿元家具有限公司,14788888888,黎忠清,999999</div>
        </div>

        <div style="padding: 20px">
          <el-form-item prop="name" label="企业类型">
            <el-select
              v-model="ruleForm.name"
              placeholder="请选择"
              style="width: 270px"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="label">依次输入:企业名称,手机号,管理员用户名,密码</div>
          <el-form-item prop="text">
            <el-input
              type="textarea"
              rows="10"
              v-model="ruleForm.text"
            ></el-input>
          </el-form-item>
          <el-form-item
            ><el-button
              @click="submitForm('formCheck')"
              size="small"
              type="primary"
              >批量注册</el-button
            ></el-form-item
          >
          <div
            v-if="resultInfo.length"
            style="border: 1px solid #ccc; padding: 10px"
          >
            <h3>处理结果</h3>
            <el-progress
              :text-inside="true"
              :stroke-width="16"
              :percentage="percentage"
            ></el-progress>
            <div v-for="(item, index) in resultInfo" :key="index">
              <span v-if="item.status === 'rejected'" class="red"
                >第{{ index + 1 }}行,{{ item.compName }},{{ item.reason }}</span
              >
              <span v-else>成功</span>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { registerCompany } from "@/service/crm";
export default {
  data() {
    return {
      typeList: [
        {
          id: 1,
          name: "工厂",
        },
        {
          id: 2,
          name: "设计公司",
        },
        {
          id: 3,
          name: "销售公司",
        },
        {
          id: 4,
          name: "共享打样",
        },
        {
          id: 5,
          name: "共享喷涂",
        },
        {
          id: 6,
          name: "供应商",
        },
        {
          id: 7,
          name: "物流公司",
        },
        {
          id: 8,
          name: "金融服务公司",
        },
        {
          id: 9,
          name: "风控公司",
        },
        {
          id: 99,
          name: "政府",
        },
        {
          id: 9901,
          name: "工业设计中心",
        },
        {
          id: 9902,
          name: "转企升规办",
        },
        {
          id: 9903,
          name: "家具产业促进局",
        },
      ],
      rules: {
        name: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        text: [
          { required: true, message: "请输入注册数据", trigger: "change" },
        ],
      },
      ruleForm: {
        name: null,
        text: null,
      },
      resultInfo: [],
      percentage: 0,
    };
  },
  methods: {
    submitForm() {
      this.$refs["formCheck"].validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    registerCompany(name = "", companyInfo = "") {
      return new Promise((resolve, reject) => {
        const list = companyInfo.split(",");
        registerCompany({
          comp_type: name,
          comp_name: list[0],
          user_mobile: list[1],
          user_name: list[2],
          user_pass: list[3],
        })
          .then((rst) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    async submit() {
      this.resultInfo = [];
      let i = 0;
      const companyList = this.ruleForm.text.split("\n");
      for (let companyInfo of companyList) {
        try {
          await this.registerCompany(this.ruleForm.name, companyInfo);
          this.resultInfo.push({ status: "resolved" });
        } catch (e) {
          this.resultInfo.push({
            status: "rejected",
            reason: e,
            compName: companyInfo.split(",")[0],
          });
        }
        i++;
        this.percentage = Math.round((i * 100) / companyList.length);
      }
    },
  },
};
</script>
<style scoped src="./style.css"></style>
<style scoped>
.red {
  color: tomato;
}
.list > div {
  padding: 5px 0;
}
div >>> .el-form-item__label {
  font-weight: 600;
}
.input-radio {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.label-radio {
  position: relative;
  top: 2px;
  margin-right: 8px;
  margin-left: 12px;
}
.label-text {
  font-size: 14px;
  color: #4d4d4d;
}
.label-text.active {
  color: #5074ee;
}

.tips {
  padding: 30px;
  border-bottom: 1px solid #eee;
}

.label {
  font-size: 14px;
  color: #606266;
  font-weight: 600;
}
</style>